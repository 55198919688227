import http from '../../utils/http';

export default {
    /**
     * 轮播图
     *
     * @param {*} data
     * @returns
     */
    banner() {
        return http({
            url: 'commom/banner',
            method: 'GET'
        });
    },
    /**
     * 获取首页轮播图
     *
     * @returns
     */
    getHomeBanner() {
        return http({
            url: 'Article/GetBanner',
            params: {
                type: 2
            },
            headers: {
                showLoading: false
            }
        });
    },
    /**
     * 获取科目一,科目四首页分类
     *
     * @returns
     */
    getHomeCategory(data) {
        return http({
            url: 'Problem/GetPCHomeProblemCategory',
            headers: {
                showLoading: false
            },
            params: data
        });
    },
    /**
     * 获取车型列表
     *
     * @returns
     */
    getCarTypeList() {
        return http({
            url: 'Problem/GetCarList',
            headers: {
                showLoading: false
            }
        });
    }
};
