// http请求相关
import axios from 'axios';
import Cookies from 'js-cookie';
import store from '../store';
// import router from '../router';

import {
    Message,
    Loading
} from 'element-ui';

const http = axios.create({
    baseURL: process.env.VUE_APP_BASE_API + 'api/',
    withCredentials: true,
    timeout: 15000,
    headers: {
        showLoading: true
    }
});

let loading = null;

let notShowError = false;

// 请求拦截 服务端设置cookie，不需要客户端手动添加，需要其他请求预处理时再使用
http.interceptors.request.use(
    config => {
        // 添加token
        var token = Cookies.get('tokenKey');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        // config.headers['Content-Type'] = 'application/json;charset=utf-8';
        if (config.headers.showLoading) {
            loading = Loading.service({
                lock: true
            });
        }
        if (config.headers.notShowError) {
            notShowError = true;
        }
        delete config.headers.notShowError;
        delete config.headers.showLoading;
        return config;
    },
    error => {
        console.error(error);
        return Promise.reject(error);
    }
);

// 响应拦截
http.interceptors.response.use(
    response => {
        if (loading) {
            loading.close();
        }
        // if (response.status !== 200) {
        //     Message({
        //         message: response.Message || 'Error',
        //         type: 'error',
        //         duration: 5 * 1000,
        //         showClose: true
        //     });
        //     return Promise.reject(new Error(response.Message || 'Error'));
        // }
        const res = response.data;
        if (!res.flag && !notShowError) {
            Message({
                message: res.msg || 'Error',
                type: 'error',
                duration: 5 * 1000,
                showClose: true
            });
            return Promise.reject(new Error(res.msg || 'Error'));
        } else {
            return Promise.resolve(res);
        }
    },
    error => {
        let unauthorized = false;
        if (loading) {
            loading.close();
        }
        let msg = error.message;
        if (msg && msg.search('Network Error') >= 0) {
            msg = '网络错误，请检查网络';
        }
        if (msg && msg.search('timeout') >= 0) {
            msg = '请求超时，请检查网络后重试';
        } else {
            if (error.response && error.response.data && error.response.data.Message) {
                msg = error.response.data.Message;
            }
            if (error.response && error.response.status === 401) {
                unauthorized = true;
                msg = store.state.token.token ? '登录信息过期，请重新登录' : '请先完成登录';
                store.dispatch('user/deleteUser');
                store.dispatch('token/deleteToken');
                if (!store.state.token.showLogin) {
                    store.dispatch('token/setShowLogin', true);
                }
                // router.push('/home');
            }
        }
        if (!unauthorized) {
            Message({
                message: msg,
                type: 'error',
                duration: 5 * 1000,
                showClose: true
            });
        }
        return Promise.reject(error);
    }
);

export default http;
