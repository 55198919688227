import axios from 'axios';
import qs from 'qs';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const getToken = (data) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_BASE_API}Token`, qs.stringify(data), {
            timeout: 30000,
            withCredentials: true,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err);
        });
    });
};

/**
 *  获取当前用户信息
 */
const getUser = () => {
    const ciphertext = Cookies.get('currentUser');
    const bytes = CryptoJS.AES.decrypt(ciphertext, 'qazxswedcvfr');
    const str = bytes.toString(CryptoJS.enc.Utf8);
    if (!str) {
        return null;
    }
    return JSON.parse(str);
};

export default {
    getToken,
    getUser
};
