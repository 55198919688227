import passport from './modules/passport';
import home from './modules/home';
import article from './modules/article';
import header from './modules/header';
import hotline from './modules/hotline';
import help from './modules/help';
import video from './modules/video';
import question from './modules/question';
import exam from './modules/exam';

export default {
    ...passport,
    ...home,
    ...article,
    ...header,
    ...hotline,
    ...help,
    ...video,
    ...question,
    ...exam
};
