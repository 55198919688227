import Vue from 'vue';
import Vuex from 'vuex';
import token from './modules/token';
import user from './modules/user';
import car from './modules/car';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        token,
        user,
        car
    }
});
