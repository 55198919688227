<template>
    <div
        class="no-content"
        :style="style"
    >
        <img src="../images/no_data.png" />
    </div>
</template>

<script>
// 无内容时显示的组件
export default {
    name: 'noContent',
    props: {
        content: {
            type: String,
            default: '暂无内容'
        },
        size: {
            type: String,
            default: 'md'
        }
    },
    computed: {
        style() {
            const obj = { fontSize: '16px' };
            switch (this.size) {
                case 'sm':
                    obj.fontSize = '14px';
                    break;
                case 'lg':
                    obj.fontSize = '18px';
                    break;
                case 'md':
                    obj.fontSize = '16px';
                    break;
            }
            return obj;
        }
    }
};
</script>

<style scoped lang="scss">
.no-content {
    height: 100%;
    color: rgb(170, 170, 170);
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
