const state = {
    car: null,
    selectId: 0
};

const mutations = {
    SET_CAR: (state, car) => {
        state.car = car;
    },
    SET_SELECTID: (state, selectId) => {
        state.selectId = selectId;
    }
};

const actions = {
    /**
     * 设置车型
     *
     * @param {*} { commit }
     * @param {*} data
     */
    setCar({
        commit
    }, data) {
        commit('SET_CAR', data);
    },
    /**
     * 选择车型
     *
     * @param {*} {
     *         commit
     *     }
     * @param {*} data
     */
    setSelectId({
        commit
    }, data) {
        commit('SET_SELECTID', data);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
