import http from '../../utils/http';

export default {
    /**
     * 获取顺序练习题id列表
     *
     * @param {*} data
     * @returns
     */
    sequenceQuestionList(data) {
        return http({
            url: 'Problem/GetProblemList',
            params: data
        });
    },
    /**
     * 获取题目
     *
     * @param {*} data
     * @returns
     */
    getSequenceQuestion(data) {
        return http({
            url: 'Problem/GetProblem',
            params: data
        });
    },
    /**
     * 保存错题
     *
     * @param {*} data
     * @returns
     */
    saveWrongQuestion(data) {
        return http({
            url: 'Problem/ProblemErrorSave',
            data: data,
            method: 'POST'
        });
    },
    /**
     * 根据车型id、科目获取二级分类
     *
     * @param {*} data
     * @returns
     */
    getProblemCategoryByCarId(data) {
        return http({
            url: 'Problem/GetPCProblemCategoryByCarId',
            params: data
        });
    },
    /**
     * VIP二级分类
     *
     * @param {*} data
     * @returns
     */
    getVIPProblemCategoryByCarId(data) {
        return http({
            url: 'Problem/GetPCVIPProblemCategory',
            params: data
        });
    },
    /**
     * 获取下级分类
     *
     * @param {*} data
     * @returns
     */
    getSubCategory(data) {
        return http({
            url: 'Problem/GetProblemSubCategory',
            params: data
        });
    },
    /**
     * 获取错题列表
     *
     * @param {*} data
     * @returns
     */
    getErrorProblem(data) {
        return http({
            url: 'Problem/GetProblemError',
            params: data
        });
    },
    /**
     * 移除答题记录
     *
     * @param {*} data
     * @returns
     */
    removeProblemRecord(data) {
        return http({
            url: 'Problem/ProblemErrorRemove',
            params: data
        });
    }
};
