import http from '../../utils/http';

export default {
    /**
     * 获取视频播放排行
     *
     * @param {*} data
     * @returns
     */
    getVideoSort(type) {
        return http({
            url: 'Problem/GetVideoSort?type=' + type,
            method: 'GET'
        });
    },
    /**
      *获取详情
      * @param {*} id
      */
    getArcitleDetail(id) {
        return http({
            url: 'Article/GetArticleInfo',
            method: 'GET',
            params: { id: id }
        });
    },
    /**
    * 学车提示
    * @param {*} id
    */
    getTips(type) {
        return http({
            url: 'Problem/LearnVideo',
            method: 'GET',
            params: { type: type }
        });
    },
    /**
     *学车提示详情
     * @param {*} id
     */
    getTipInfo(id) {
        return http({
            url: 'Article/GetSystemInfo',
            method: 'GET',
            params: { id: id }
        });
    },
    /**
     *获取视频列表
     * @param {*} type 科目
     */
    getVideo(data) {
        return http({
            url: 'Problem/GetVideoList',
            method: 'GET',
            params: data
        });
    },
    /**
     * 视频详情
     * @param {*}} id
     */
    getVideoInfo(id) {
        return http({
            url: 'Problem/GetVideoInfo?id=' + id,
            method: 'GET'
        });
    }
};
