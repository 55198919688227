import http from '../../utils/http';

export default {
    /**
     * 登录
     * @param {*} data
     */
    login(data) {
        return http({
            url: 'Login',
            method: 'GET',
            params: data
        });
    },
    /**
     * 注册
     * @param {*} data
     */
    reg(data) {
        return http({
            url: 'Login/Register',
            method: 'POST',
            data
        });
    },
    /**
     * 获取验证码
     * @param {*} phone
     */
    getCode(phone) {
        return http({
            url: 'Login/GetMsgCode?phone=' + phone,
            method: 'GET'
        });
    },
    /**
     * 激活码激活
     */
    activeVipByCode(code) {
        return http({
            url: 'User/ActivaCode?code=' + code,
            method: 'GET'
        });
    },
    /**
     * 套餐激活
     */
    activeVipByPackage(id) {
        return http({
            url: 'User/ActivaPackage?packageId=' + id,
            method: 'GET'
        });
    },
    /**
     * 获取套餐列表
     */
    getPackage() {
        return http({
            url: 'User/GetPackage',
            method: 'GET'
        });
    },
    wxLogin(data) {
        return http({
            url: 'Login/WxLogin',
            method: 'POST',
            data
        });
    },
    scanPay(orderNo) {
        return http({
            url: `User/PCOrderPay?orderNo=${orderNo}`,
            method: 'get'
        });
    },
    checkOrder(id) {
        return http({
            url: 'User/CheckOrder?id=' + id,
            method: 'get',
            headers: {
                notShowError: true,
                showLoading: false
            }
        });
    }
};
