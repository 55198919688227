// ie
import 'core-js';
import 'regenerator-runtime/runtime';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import VueAwesomeSwiper from 'vue-awesome-swiper';

// custom
import api from './api';
import util from './utils';
import noContentCmp from './components/no-content.vue';

// 全局样式
import '@/styles/index.scss';
import '@/styles/animate.css';
// import 'swiper/css/swiper.css';

Vue.use(ElementUI);
// Vue.use(VueAwesomeSwiper);
// 全局组件注册
Vue.component('no-content', noContentCmp);

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$util = util;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
