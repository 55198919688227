import http from '../../utils/http';

export default {
    /**
     * 获取代理/学员
     *
     * @param {*}
     * @returns
     */
    getHelpList(data) {
        return http({
            url: 'Article/GetSystem',
            method: 'GET',
            params: data
        });
    },
    /**
     *获取学员
     * @param {*}
     */
    // getHelpList2() {
    //     return http({
    //         url: 'Article/GetSystem?page=1&pageSize=10&type=5&listType=3',
    //         method: 'GET'
    //     });
    // },
    getHelpInfo(id) {
        return http({
            url: 'Article/GetSystemInfo?id=' + id,
            method: 'GET'
        });
    }
};
