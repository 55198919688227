<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    created() {
        this.getCarList();
    },
    methods: {
        /**
         * 获取车型列表
         */
        getCarList() {
            this.$api.getCarTypeList().then(res => {
                if (res.flag) {
                    this.$store.dispatch('car/setCar', res.data);
                    if (res.data[0]) {
                        this.$store.dispatch('car/setSelectId', res.data[0].Id);
                    }
                }
            });
        }
    }
};
</script>
