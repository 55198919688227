import http from '../../utils/http';

export default {
    /**
     *获取客服热线
     * @param {*} id
     */
    getHotline() {
        return http({
            url: 'Article/GetSystem?page=1&pageSize=1&type=0&listType=2',
            method: 'GET'
        });
    }
};
