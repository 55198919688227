import http from '../../utils/http';

export default {
    /**
     * 考试题目
     *
     * @param {*} data
     * @returns
     */
    getProblemTestList(data) {
        return http({
            url: 'Problem/GetTestProblemList', // ?type=1&carId=6
            method: 'GET',
            params: data
        });
    },
    /**
     * 题目信息
     */
    getProblemInfo(id) {
        return http({
            url: 'Problem/GetProblem?problemId=' + id,
            method: 'GET'
        });
    },
    getCarInfo(id) {
        return http({
            url: 'Problem/Car/Info?id=' + id,
            method: 'GET'
        });
    },
    /**
     *根据分数获取图片
     *
     * @param {*} data
     * @returns
     */
    getExamImg(data) {
        return http({
            url: 'Problem/GetExamImgBySource',
            params: data
        });
    }
};
