import http from '../../utils/http';

export default {
    /**
     * 登录
     *
     * @param {*} data
     * @returns
     */
    login(data) {
        return http({
            url: 'user/login',
            method: 'POST',
            data
        });
    },
    /**
     * 注册
     *
     * @param {*} data
     * @returns
     */
    register(data) {
        return http({
            url: 'user/register',
            method: 'POST',
            data
        });
    },
    /**
     * 获取用户注册协议
     *
     * @returns
     */
    getRegisterAgreement() {
        return http({
            url: 'Article/GetSystem?page=1&pageSize=10&type=0&listType=7',
            method: 'GET'
        });
    },
    /**
     * 获取用户隐私政策
     *
     * @return {*}
     */
    getRegisterPrivacy() {
        return http({
            url: 'Article/GetSystem?page=1&pageSize=10&type=0&listType=8',
            method: 'GET'
        });
    },
    /**
     * 获取用户信息
     *
     * @returns
     */
    getUserInfo() {
        return http({
            url: 'User/GetUserInfo',
            headers: {
                showLoading: false,
                notShowError: true
            }
        });
    }
};
