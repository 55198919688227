import http from '../../utils/http';

export default {
    /**
     * 获取列表
     *
     * @param {*} data
     * @returns
     */
    getArticleList(data) {
        return http({
            url: 'Article/GetArticle',
            method: 'GET',
            params: data
        });
    },
    /**
     *获取详情
     * @param {*} id
     */
    getArcitleDetail(id) {
        return http({
            url: 'Article/GetArticleInfo',
            method: 'GET',
            params: {
                id: id
            }
        });
    }
};
