const state = {
    token: null,
    showLogin: false
};

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
    DELETE_TOKEN: (state) => {
        state.token = null;
    },
    SET_SHOWLOGIN: (state, data) => {
        state.showLogin = data;
    }
};

const actions = {
    /**
     * 获取token
     *
     * @param {*} {
     *         commit,
     *         state
     *     }
     * @param {*} data
     */
    setToken({
        commit
    }, data) {
        commit('SET_TOKEN', data);
    },
    deleteToken({
        commit
    }) {
        commit('DELETE_TOKEN');
    },
    /**
     * 显示登录弹窗
     *
     * @param {*} {
     *         commit
     *     }
     * @param {*} data
     */
    setShowLogin({
        commit
    }, data) {
        commit('SET_SHOWLOGIN', data);
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};
