import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

/*
 * 需要缓存的路由在meta中添加 keepAlive: true
 * 需要登录的页面在meta中添加 needLogin : true
 */
const routes = [{
        path: '/',
        component: () => import('../layout/index.vue'),
        children: [{
                path: '',
                redirect: 'home'
            },
            {
                path: 'home',
                name: '首页',
                meta: {
                    keepAlive: true
                },
                component: () => import('../views/home/home.vue')
            }, {
                path: 'agreement',
                name: '用户服务协议',
                meta: {
                    needLogin: false
                },
                component: () => import('../views/home/agreement.vue')
            }, {
                path: 'privacy',
                name: '用户隐私政策',
                meta: {
                    needLogin: false
                },
                component: () => import('../views/home/privacy.vue')
            },
            {
                path: 'choiceCar',
                name: 'choice_car',
                title: '选择车型',
                component: () => import('../views/exam/choiceCar.vue')
            },
            {
                path: 'modelTest',
                name: 'model_test',
                title: '模拟考试',
                meta: {
                    showHeader: true
                },
                component: () => import('../views/exam/modelTest.vue')
            },
            {
                path: 'submitExam',
                name: 'submit_exam',
                title: '提交试卷',
                component: () => import('../views/exam/submitExam.vue')
            },
            {
                path: 'article',
                name: 'article',
                title: '文章中心',
                component: () => import('../views/article/article.vue')
            },
            {
                path: 'articleDetails/:id',
                name: 'articleDetails',
                title: '文章中心',
                component: () => import('../views/article/details.vue')
            },
            {
                path: 'hotline',
                name: 'hotline',
                title: '客服热线',
                component: () => import('../views/hotline/index.vue')
            },
            {
                path: 'help',
                name: 'help',
                title: '帮助中心',
                component: () => import('../views/help/index.vue')
            },
            {
                path: 'helpDetails',
                name: 'helpDetails',
                title: '帮助详情',
                component: () => import('../views/help/details.vue'),
                props: (route) => ({
                    id: route.query.id,
                    type: route.query.type
                })
            },
            {
                path: 'video',
                name: 'video',
                title: '学车视频',
                meta: {
                    keepAlive: true
                },
                component: () => import('../views/video/video.vue')
            },
            {
                path: 'videoDtails',
                name: 'videoDtails',
                title: '学车视频',
                component: () => import('../views/video/details.vue')
            },
            {
                path: 'videoArticle',
                name: 'videoArticle',
                title: '详情',
                component: () => import('../views/video/article.vue')
            },
            {
                path: 'activeVip',
                name: 'active_vip',
                title: 'VIP',
                component: () => import('../views/vip/activeVip.vue')
            },
            {
                path: 'examExercises',
                name: 'examExercises',
                title: '顺序练题',
                component: () => import('../views/exam/exercises.vue')
            },
            {
                path: 'skillTest/:id',
                name: 'skill_test',
                title: '技巧练习',
                component: () => import('../views/exam/skillTest.vue')
            }
        ]
    },
    // 错误路由
    {
        path: '/404',
        name: 'error',
        component: () => import('../layout/error.vue')
    }, {
        path: '*',
        redirect: '/404'
    }
];

const router = new VueRouter({
    routes
});

export default router;
