const state = {
    user: null,
    isVip: false
};

const mutations = {
    SET_USER: (state, user) => {
        state.user = user;
    },
    DELETE_USER: (state) => {
        state.user = null;
    },
    SET_ISVIP: (state, isVip) => {
        state.isVip = isVip;
    }
};

const actions = {
    /**
     * 设置用户信息
     *
     * @param {*} { commit }
     * @param {*} data
     */
    setUser({
        commit
    }, data) {
        commit('SET_USER', data);
    },
    /**
     * 删除用户
     *
     * @param {*} {
     *         commit
     *     }
     */
    deleteUser({
        commit
    }) {
        commit('DELETE_USER');
    },
    /**
     * 设置isvip
     *
     * @param {*} {
     *         commit
     *     }
     * @param {*} data
     */
    setIsVip({
        commit
    }, data) {
        commit('SET_ISVIP', data);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
